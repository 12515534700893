@import 'src/variables.scss';
@import 'src/styling/palette.scss';

.cancel-call-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  row-gap: 20px;
  min-height: 50vh;
}

.cancel-call-container-buttons {
  display: inline-flex;
  flex-direction: row;
  column-gap: 30px;
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
  }
}

.confirm-call {
  .custom-call-provider {
    margin: 50px 50px;
  }

  .error {
    color: $error;
  }

  .custom-button {
    font-family: 'Gilroy';
    font-weight: 700;
    margin: 20px 20px 0 0;
    padding: 10px;
    background: #e8e8e8;
    border-radius: 2px;
    border: none;
  }

  .button-disabled {
    background-color: #e8e8e8;
    color: #777777;
    border: 0;
    cursor: not-allowed;
  }

  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
    .time-slot {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}

.thank-you-container {
  display: flex;
  justify-content: center;
  background: #ffffff;

  .button-primary {
    background-color: $primary;
    border: 1px solid $primary;
    color: $contrastTextPrimary;

    &:hover {
      background-color: $primary;
      border: 1px solid $contrastTextPrimary;
    }
  }

  .remove-button {
    background-color: #ee5c5c;
    border: 1px solid #ee5c5c;
    color: #ffffff;

    &:hover {
      background-color: #fb8888;
      border: 1px solid #000;
    }
  }

  .button-secondary {
    &:hover {
      background-color: #c2c2c2;
    }
  }

  .button-wrapper {
    margin-top: 44px;

    button {
      &:first-child {
        max-width: 286px;
        margin-right: 27px;

        @include media('<phone') {
          margin-bottom: 20px;
        }
      }

      &:last-child {
        max-width: 203px;
      }
    }
  }

  .addeventatc.button-primary {
    font-family: gilroy;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 0;
    background-color: c2c2c2;
    border: 1px solid c2c2c2;
    color: #0f0f22;

    &:hover {
      border: 1px solid #000;
    }

    .addeventatc_icon {
      display: none;
    }
  }
}

.thank-you {
  margin: 30px;
  max-width: 600px;
  background: #ffffff;
  @include media('<phone') {
    max-width: 90%;
  }

  h3 {
    font-size: 36px;
    margin: 0;
    margin-bottom: 20px;
  }

  .options-buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.appointments-expired-container {
  display: flex;
  justify-content: center;
  margin: 0 16px;

  @include media('>phone') {
    margin: 0 20px;
  }
}

.appointments-expired {
  max-width: 700px;
  margin-top: 42px;
  text-align: left;

  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 36px;
    color: #000;
  }

  .button-wrapper {
    margin-top: 44px;

    button {
      &:first-child {
        max-width: 286px;
        margin-right: 27px;

        @include media('<phone') {
          margin-bottom: 20px;
        }
      }

      &:last-child {
        max-width: 203px;
      }
    }
  }

  .button-primary {
    background-color: $primary;
    border: 1px solid $primary;
    color: $contrastTextPrimary;

    &:hover {
      background-color: $primary;
      border: 1px solid $contrastTextPrimary;
    }
  }

  .button-secondary {
    &:hover {
      background-color: c2c2c2;
    }
  }
}

.propose-another-time-button {
  margin-left: 10px;
}

.confirm-call__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.confirm-call__time-slots {
  margin-top: 30px;
  .time-slot {
    margin-bottom: 15px;
  }
}

.confirm-call__button-container {
  display: flex;
  align-items: center;

  margin-top: 40px;
}

.confirm-call__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-call__loading-spinner {
  height: 200px;
  width: 200px;
}

.confirm-call__content-wrapper {
  margin-top: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.confirm-call__choose-call-provider-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 17px;
  margin-bottom: 17px;
  h5 {
    margin: 0px;
  }
}

.confirm-call__choose-call-provider-dropdown {
  min-width: 400px;
}

.confirm-call__provider-call-tooltip {
  position: relative;
  margin-left: 5px;
  z-index: 0;
}

.confirm-call__call-url-input-text {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.confirm-call__error-hidden {
  visibility: hidden;
}
