@import 'src/variables.scss';

.tag {
  display: inline-flex;
  color: #000000;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  padding: 4px 8px;
  line-height: 24px;
  border-radius: 2px;

  svg {
    margin-left: 10px;
    margin-top: 1px;
    cursor: pointer;
  }
}

.tag-primary {
  background-color: $tag-green;
}
.tag-secondary {
  background-color: $tag-blue;
}
.tag-tertiary {
  background-color: $tag-grey;
}

.tag__clickable {
  cursor: pointer;
}
