@import 'src/variables.scss';

.group-event-briefing__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 0 50px;
  text-align: left;
  white-space: pre-line;
}

.group-event-briefing__title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.group-event-briefing__title-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid $shade-grey;
  padding: 0 0 24px 0;
  max-width: 1200px;
  flex: 1;
}

.group-event-briefing__title {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
  @media screen and (max-width: 750px) {
    font-size: 28px;
  }
}

.group-event-briefing__subtitle {
  margin: 8px 0 0 0;
  font-size: 28px;
  @media screen and (max-width: 750px) {
    font-size: 18px;
  }
}

.group-event-briefing__body-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 972px;
  flex: 1;
  padding: 25px 0 33px 0;
  border-bottom: 1px solid $tag-grey;
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
}

.group-event-briefing__card-container {
  display: flex;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.group-event-briefing__card {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  padding: 10px 20px 20px;
  margin-top: 20px;
  border-radius: 2px;
}

.group-event-briefing__details-card {
  flex: 1;
  @media screen and (max-width: 750px) {
    margin: 0;
    width: 300px;
    padding: 20px;
  }
}
.group-event-briefing__agenda-card {
  flex: 2;
  margin-left: 20px;
  @media screen and (max-width: 750px) {
    margin: 0;
    width: 300px;
    padding: 20px;
  }
}

.group-event-briefing__card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }
}

.group-event-briefing__card-row-1 {
  max-width: 75%;
}
.group-event-briefing__card-row-2 {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
}

.group-event-briefing__card-cta-button {
  margin-top: 10px;
  position: relative;
  width: 100%;

  &:disabled {
    opacity: 1;
    background-color: rgba(83, 255, 200, 0.4);
    color: rgba(0, 0, 0, 0.4);
  }

  &:first-of-type {
    margin-top: 15px;
  }
}

.group-event-briefing__atc-button {
  margin-top: 10px;
  width: 100%;
}

.group-event-briefing__body-title {
  font-size: 20px;
  margin: 0 0 20px;
  width: 100%;
}

.group-event-briefing__requests-title {
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 0;
}

.group-event-briefing__requests-container {
  margin-top: 15px;
}

.group-event-briefing__request-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid $tag-grey;
  align-items: flex-start;
}

.group-event-briefing__request-profile-container {
  display: flex;
}

.group-event-briefing__request-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.group-event-briefing__request-profile-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.group-event-briefing__request-profile-name {
  font-size: 14px;
  font-weight: 600;
}

.group-event-briefing__request-profile-org-name {
  font-size: 14px;
}

.group-event-briefing__request-body-text {
  width: 100%;
  font-size: 17px;
}

.group-event-briefing__request-expertise-text {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 0px;
  font-size: 14px;
}

.group-event-briefing__dummy-requests-container {
  position: relative;
}

.group-event-briefing__dummy-requests-overlay {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.group-event-briefing__request-profile-name--dummy {
  color: transparent;
  background-color: $tag-grey;
  align-self: flex-start;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.group-event-briefing__request-profile-org-name--dummy {
  color: transparent;
  background-color: $tag-grey;
  margin-top: 10px;
  align-self: flex-start;
  margin-bottom: 20px;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.group-event-briefing__request-body-text--dummy {
  color: transparent;
  background-color: $tag-grey;
  align-self: flex-start;
  width: auto;
  margin-top: 0;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.group-event-briefing__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.group-event-briefing__loading-spinner {
  height: 200px;
  width: 200px;
}

.group-event-briefing__tooltip-icon-container {
  position: absolute;
  top: -7px;
  right: -17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(47, 143, 232);
  border-radius: 18px;
  width: 14px;
  height: 14px;
  pointer-events: initial;
  opacity: 1;
  color: black;

  &:hover .group-event-briefing__tooltip-text-container {
    display: flex;
    flex-direction: column;
    opacity: 1;
  }
  &:hover .group-event-briefing__tooltip-pointer {
    display: block;
    opacity: 1;
  }
}

.group-event-briefing__tooltip-icon {
  width: 5px;
  height: 8px;
  opacity: 1;
}

.group-event-briefing__tooltip-text-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 350px;
  background: white;

  display: none;
  position: absolute;
  bottom: 150%;
  z-index: 20;
  opacity: 1;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    display: block;
  }

  @media (max-width: 1350px) {
    transform: translate(-45%);
  }
}

.group-event-briefing__tooltip-pointer {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 100%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
  z-index: 20;
  display: none;
  opacity: 1;

  &:hover .group-event-briefing__container {
    display: block;
  }
}
