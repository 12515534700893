.impact-charts-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 36px;
  width: 100%;
  @media screen and (max-width: 950px) {
    width: calc(min(90vw, 330px));
  }
}
.statistics-count {
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 18px;
  }
}
.align-charts {
  display: flex;
  column-gap: 20px;
  row-gap: 30px;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
}

.impact-empty-page-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50vh;
  row-gap: 20px;
  justify-items: center;
}

.not-found-small-text {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: center;
  color: #000;
}

.loading-button-reviews {
  width: 654px;
  min-height: 162px;

  @media screen and (max-width: 950px) {
    width: 320px;
  }
}
