.vol-details-wrapper {
  width: 1000px;
  margin-right: 40px;
  display: flex;
  align-items: flex-start;
  gap: 12px;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    width: 100%;
    padding-right: 20px;
  }

  .vol-details-container {
    width: 70%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .vol-details-header {
      width: 100%;
      height: 160px;
      box-sizing: border-box;
      padding: 26px;
      border-radius: 8px;
      background-color: #b8eaf0;
      position: relative;

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        gap: 20px;
      }

      .vol-details-photo {
        height: 182px;
        width: 182px;
        border-radius: 182px;
        object-fit: cover;
        position: absolute;
        top: 68px;
        left: 40px;

        @media screen and (max-width: 1024px) {
          min-height: 182px;
          min-width: 182px;
          position: relative;
          top: unset;
          left: unset;
        }
      }
    }

    .vol-details-info-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 40px;
      gap: 16px;

      @media screen and (max-width: 1024px) {
        padding-left: 0;
        flex-direction: column;
      }

      .vol-details-info-left {
        width: 100%;
        box-sizing: border-box;
        margin-top: 120px;

        @media screen and (max-width: 1024px) {
          margin-top: 16px;
        }

        .vol-details-name {
          font-family: 'Gilroy';
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0.28px;
          color: #000;
          margin-bottom: 4px;
        }

        .vol-details-employer {
          font-family: 'Gilroy';
          font-size: 17px;
          font-weight: 500;
          letter-spacing: 0.17px;
          color: #0f0f22;
        }

        .vol-details-label {
          font-family: 'Gilroy';
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.42px;
          color: #000;
          margin: 24px 0 8px;
        }

        .vol-details-summary {
          font-family: 'Gilroy';
          font-size: 14px;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: 0.28px;
          color: #000;
        }

        .vol-details-read-button {
          font-family: 'Gilroy';
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.28px;
          color: #2f80ed;
          border: none;
          padding: 0;
          text-decoration: none;
          margin-top: 8px;
          text-transform: capitalize;
        }

        .vol-details-anchor {
          margin-top: 25px;

          .vol-details-linkedin {
            font-family: 'Gilroy';
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.42px;
            color: #000;

            span {
              color: #0274b3;
            }

            img {
              width: 15px;
              height: 15px;
              margin-left: 2px;
            }
          }
        }
      }

      .vol-details-info-right {
        min-width: 300px;
        border-radius: 8px;
        background-color: #ededed;
        padding: 24px 16px 24px;
        margin-top: 16px;

        @media screen and (max-width: 1024px) {
          min-width: unset;
          width: 100%;
          margin-top: 0;
        }

        .vol-details-label {
          font-family: 'Gilroy';
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.42px;
          color: #000;
          margin: 24px 0 8px;
        }

        .vol-details-day-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 12px;
          margin-bottom: 20px;

          .vol-details-day-tag {
            padding: 4px 8px;
            border-radius: 50px;
            background-color: #b2d5ef;
            font-family: 'Gilroy';
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.28px;
            color: #333333;
          }

          .vol-details-day-tag-disabled {
            opacity: 0.4;
          }
        }

        .vol-details-time {
          font-family: 'Gilroy';
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.28px;
          color: #000;
        }

        .vol-details-divider {
          height: 1px;
          width: 100%;
          background: #166b7a;
          margin: 24px 0;
        }

        .vol-details-interests-wrapper {
          display: flex;
          flex-direction: column;
          gap: 14px;
          max-height: 240px;
          overflow: auto;

          .vol-details-interests {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
          }
        }

        .vol-details-help {
          font-family: 'Gilroy';
          font-size: 17px;
          font-weight: 500;
          line-height: 1.2;
          letter-spacing: 0.17px;
          color: #0f0f22;
          margin-bottom: 14px;
        }
      }
    }
  }

  .vol-details-question {
    background: #2f80ed;
    border-radius: 18px;
    min-width: 14px;
    min-height: 14px;
    display: inline-block;
    position: relative;

    img {
      position: absolute;
      top: 3px;
      left: 5px;
    }
  }

  .vol-details-share-container {
    width: 30%;
    background: #ededed;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;

    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.17px;
    color: #0f0f22;

    span {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .vol-details-share-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 24px;
      border-radius: 8px;

      button {
        align-self: flex-end;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Gilroy';
        font-size: 14px;
        font-weight: 600;

        margin-bottom: 6px;
      }

      p {
        font-family: 'Gilroy';
        font-size: 17px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.17px;
      }
    }
  }
}
