@import 'src/variables.scss';

.dashboard-box {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 950px) {
    height: auto;
    margin-bottom: 0;
  }
}

.rectangle-icon-container {
  margin-top: 10px;
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}
.sent-reschedule-rectangle-icon {
  display: flex;
  background-color: #f1a224;
  justify-content: flex-end;
  align-items: flex-end;
  width: 110px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.sent-reschedule-rectangle-icon:after {
  width: 40px;
  margin: -4px;
  height: 38px;
  border-radius: 80%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 10px;
  min-height: 70px;
}

.rectangle-icon-text {
  padding-top: 10px;
  width: 90px;
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #fff;
  height: 31px;
  text-align: center;
  justify-content: center;
}

.dashboard-card__container {
  display: grid;
  grid-template-columns: 80px auto;
  @media screen and (max-width: 950px) {
    grid-template-columns: 70px 265px;
  }
}

.replay-card-header-info {
  display: inline-flex;
  flex-direction: column;
  margin: 30px;
  row-gap: 8px;
  @media screen and (max-width: 950px) {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.dashboard-card-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: -15px;
  @media screen and (max-width: 950px) {
    margin-top: 10px;
  
  }
}
.completed-dashboard-card-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: -10px;
  @media screen and (max-width: 950px) {
    margin-top: 10px;
    margin-left: 20px;
  }
}
.dashboard-card-title {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: left;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.dashboard-card-name {
  display: inline-flex;
  flex-grow: 0;
  font-family: Gilroy;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  color: #000;
  text-transform: capitalize;
  @media screen and (max-width: 950px) {
    font-size: 14px;
    flex-direction: column;
    row-gap: 5px;
  }
}

.dashboard-paper-time-hour {
  align-items: flex-end;
  display: flex;
  column-gap: 4px;
}
.dashboard-paper-time-day {
  align-items: flex-end;
  display: flex;
  column-gap: 4px;
}

.dashboard-button-container {
  width: auto;
  @media screen and (max-width: 950px) {
    width: calc(min(80vw, 290px));
    margin-left: -70px;
  }
}

.dashboard-link-button-container {
  margin-left: 5px;
  @media screen and (max-width: 950px) {
    width: calc(min(80vw, 300px));
    margin-left: -70px;
    text-align: center;
  }
}

.cancel-reschedule-button-container {
  margin-left: 25px;
  width: 150px;
  @media screen and (max-width: 950px) {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
}
.reschedule-dashboard-button-container {
  @media screen and (max-width: 950px) {
    text-align: center;
  }
}
