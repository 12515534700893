@import 'src/variables.scss';

.date-picker-custom-input {
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-selected {
    background: #e8e8e8;
    color: #0f0f22;
  }

  background: $highlight-green;
  width: 220px;
  height: 45px;
  color: black;
  font-weight: 700;
  cursor: pointer;
  font-size: 17px;
  font-family: 'Gilroy';
  border: none;

  svg {
    margin-right: 7px;
    margin-left: 12px;
  }
}

.date-picker__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  justify-self: stretch;
}

.react-datepicker__input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__triangle {
  left: -15px !important;
}
