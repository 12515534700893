.select-mentor-action-plan {
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  padding: 16px;
  margin: 0 24px;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    margin: 0px;
    column-gap: 5px;
    flex-direction: column;
    align-items: baseline;
  }
  .select-user {
    display: inline-flex;
    width: auto;
    align-items: center;
  }

  .new-mentor-select-card__photo {
    height: 58px;
    width: 58px;
    border-radius: 58px;
    border: 1px solid #e8e8e8;
    background: white;
    object-fit: cover;
    margin: 0 8px 0 16px;
  }

  .new-mentor-select-card__name {
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.51px;
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.mentor-select-permission {
  display: inline-flex;
  width: 300px;
  flex-direction: row;
  margin-left: 30px;
}
.permission-popup-action-plan {
  color: #000;
  text-align: left;
  font-family: Gilroy;
  display: inline-flex;
  row-gap: 20px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 131%; /* 26.2px */
  letter-spacing: 0.4px;
  @media screen and (max-width: 950px) {
    font-size: 16px;
    row-gap: 10px;
  }
}

.user-select-permission {
  font-size: 14px;
}
