@import '../../../styling/palette.scss';

.profile-completion__Container {
  background-color: $secondary;
  border-radius: 15px;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 400px;
  margin-top: 30px;
  min-height: 200px;
}

.profile-completion-top-line {
  display: inline-flex;
  text-align: center;
  width: 100%;
  justify-content: space-between;
}
.profile-completion-tooltip {
  display: inline-flex;
  align-items: center;
}
.profile-completion-statistics {
  display: grid;
  grid-template-columns: auto;
  width: 300px;
  margin-top: 10px;
}

.profile-completed-text {
  margin: 15px;
}
