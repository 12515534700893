@import 'src/variables.scss';

.ReactModal__Body--open {
  overflow: hidden;
}

.messages-modal__modal {
  background: white;
}

.messages-modal__sent-notification {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  .messages-modal__sent-icon {
    position: absolute;
    left: 10%;
  }
  span {
    margin-left: 10%;
  }
}

.messages-modal__sent-modal-close {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages-modal__inner-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 201;
}

.messages-modal__header-icons-container {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.messages-modal__header-button {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: inline-block;
  border: none;
  background: unset;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
}

.messages-modal__block-button {
  cursor: pointer;
  display: inline-block;
  border: none;
  background: unset;
  padding: 12px 30px;
}

.messages-modal__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 15px 0;

  border-bottom: 1px solid $tag-grey;
}

.messages-modal__header-title {
  font-size: 18px;
}

.messages-modal__header-subtitle {
  font-size: 16px;
  color: $shade-grey;
  margin-top: 5px;
}

.messages-modal__messages-scroller {
  height: 70%;
  overflow-y: auto;
  padding-bottom: 40px;
}

.messages-modal__message {
  margin-top: 30px;
}

.messages-modal__input-container {
  height: 35%;
}

.messages-modal__text-input {
  border: 1px solid $tag-grey;
  min-height: 100px;
  width: 100%;
  resize: none;
  font-family: 'Gilroy';
  font-size: 18px;
  padding: 10px 15px;
}

.messages-modal__button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.messages-modal__refreshing {
  -webkit-animation: icon-spin 1.5s infinite linear;
  animation: icon-spin 0.75s infinite linear;
  margin-top: -12px;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.messages-modal__refresh-button {
  align-self: center;
  margin-top: 7px;
}

.messages-modal__send-button {
  width: 200px;
  margin-top: 10px;
  min-height: 44px;
  display: flex;
  justify-content: center;
}

.messages-modal__loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages-modal__loading-spinner {
  margin: auto;
}
