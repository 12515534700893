a:active,
a:hover {
  outline: 0;
}

.w-container:after,
.w-row:after {
  clear: both;
}

footer {
  display: block;
}

a {
  background-color: transparent;
}

img {
  border: 0;
  vertical-align: middle;
  display: inline-block;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

.w-container:after,
.w-container:before,
.w-row:after,
.w-row:before {
  display: table;
  content: ' ';
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.w-container .w-row {
  margin-left: -10px;
  margin-right: -10px;
}

.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.w-col-3 {
  width: 25%;
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
  .w-col-medium-3 {
    width: 25%;
  }
}

@media screen and (max-width: 767px) {
  .w-container .w-row,
  .w-row {
    margin-left: 0;
    margin-right: 0;
  }
  .w-col {
    width: 100%;
    left: auto;
    right: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
  .w-col {
    width: 100%;
  }
}

img {
  max-width: 100%;
}

a,
a:hover {
  text-decoration: underline;
}

p {
  margin-bottom: 10px;
}

.footer-link-1 {
  font-size: 14px;
}

a,
p {
  font-family: Gilroy, sans-serif;
  font-size: 17px;
}

p {
  display: block;
  color: #333;
  line-height: 24px;
  font-weight: 300;
}

a {
  color: #181f39;
}

a:hover {
  font-weight: 600;
}

img {
  -o-object-fit: contain;
  object-fit: contain;
}

.footer {
  text-align: center;
  margin-top: 50px;
  .bottom-logo {
    display: flex;

    .nav__logo {
      max-height: 80px;
      margin-bottom: 16px;
    }
  }

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.div-block-6 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.div-block-6,
.text-block-12 {
  margin-left: 0;
  padding-left: 0;
}

.footer {
  display: flex;
  width: 100%;
  height: 400px;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ededed;
}

.footer-link-1.nolink {
  padding: 0;
}

.footer-link-1,
.text-block-12 {
  font-family: Gilroy, sans-serif;
  color: rgb(0, 0, 0);
  text-align: left;
}

.footer-link-1 {
  display: block;
  margin-bottom: 10px;
  font-weight: 300;
  text-decoration: none;
}

.footer-link-1:hover {
  text-decoration: underline;
}

.footer-link-1.nolink {
  text-decoration: none;
}

.div-block-6 {
  display: block;
  width: 200px;
  margin-right: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-block-12 {
  min-width: 0;
}

.container-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.container-8 {
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-26 {
  display: block;
  padding-bottom: 16px;
  text-align: left;
}

.column-footer {
  padding-right: 60px;
}

.column-footer-main {
  width: 102%;
}

.div-block-27 {
  width: 100%;
}

.charity {
  margin-top: 60px;
}

.ko {
  color: rgb(0, 0, 0);
}

@media screen and (min-width: 1280px) {
  p {
    line-height: 25px;
  }
}

@media screen and (max-width: 991px) {
  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer-link-1 {
    font-size: 12px;
  }
  .div-block-6 {
    width: 140px;
    img {
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .column-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  p {
    line-height: 1.3em;
  }
  .footer {
    height: auto;
    padding: 40px 20px;
  }
  .div-block-6,
  .footer-link-1.nolink {
    padding-right: 0;
    padding-left: 0;
  }
  .column-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    padding-right: 0;
    padding-left: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 479px) {
  .footer {
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }
  .div-block-6 {
    margin-left: 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .container-8 {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  .container-8 {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .column-footer {
    margin-bottom: 40px;
  }
}

/*! CSS Used fontfaces */

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5ec78b46c6c12cd3df25755c_Gilroy-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5f210a9caa1dbc38bdc379b2_Gilroy-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5f607453290236412bbf927c_Gilroy-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5f607453e8fbbc45fe8609e9_Gilroy-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5ec78b653fc8c0d58d4a9f0c_Gilroy-ExtraBold.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5f60747714d66334d82f40e7_Gilroy-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
