@import 'src/variables.scss';

.container {
  height: 60vh;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    margin: 0 4%;
  }

  h1 {
    font-weight: 500;
    color: $primary-blue;
    margin: 0 0 20px;
    text-align: center;
  }

  p {
    color: $text-grey;
    margin: 0 0 40px;
    text-align: center;
  }
}
