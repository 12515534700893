@import 'src/variables.scss';

.profile-photo-card {
  width: 58px;
  height: 58px;
  flex-grow: 0;
  border: 1px solid rgb(114, 111, 111);
  position: relative;
  display: grid;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.upcoming-paper-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: -15px;
  @media screen and (max-width: 950px) {
    margin-top: 10px;
  }
}

.upcoming-paper-header {
  display: grid;
  grid-template-columns: 80px auto;
}

.upcoming-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;

  @media screen and (max-width: 950px) {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
  }
  @media screen and (max-width: 750px) {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -20px;
  }
}

.upcoming-group-event-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin: 0px 0 0 80px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;

  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.upcoming-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  padding-left: 4px;
}

.upcoming-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  @media screen and (max-width: 950px) {
    width: 180px;
    overflow-wrap: break-word;
  }
}

.upcoming-paper-button-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;

  @media screen and (max-width: 950px) {
    grid-template-columns: auto;
    margin-top: 20px;
  }
}

.upcoming-paper-slots {
  padding-top: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}

.upcoming-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;

  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.upcoming-rectangle-icon {
  display: flex;
  background-color: #8ac70a;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100px;
  height: 30px;
  position: relative;
  overflow: hidden;
}

.upcoming-rectangle-icon:after {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}

.upcoming-paper-box {
  width: 603px;
  height: 214px;
  flex-grow: 0;
  padding: 5px 0 24px 24px;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  @media screen and (max-width: 950px) {
    width: 328px;
    height: 386px;
    max-width: 100%;
  }
}

.masterclass-paper-box {
  width: 603px;
  height: 199px;
  flex-grow: 0;
  padding: 5px 0 24px 24px;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;

  @media screen and (max-width: 950px) {
    width: 328px;
    height: 306px;
    max-width: 100%;
  }
}
