@import 'src/variables.scss';

.input-with-label {
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;
  text-align: left;

  label {
    padding: 12px 0;
    font-size: 12px;
    color: $shade-grey;
  }

  input {
    height: 48px;
    border: 1px solid $primary-blue;
    font-size: 14px;
    padding-left: 22px;
  }
}
