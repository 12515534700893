@import 'src/variables.scss';

.messaging-rebook-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 25;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messaging-rebook-body {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #ffffff;
  padding: 16px;
  border-radius: 9px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  border: 1px solid #000;

  .messaging-rebook-close-container {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
  }

  .resources-mobile-title {
    margin-bottom: 6px;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 700;
    color: #333;
  }

  button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 600;

    margin-bottom: 12px;
  }
}
