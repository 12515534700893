@import 'src/variables.scss';

.linkedin-sign-up {
  justify-content: center;
  text-align: left;
  display: flex;
  padding-top: 50px;

  .border {
    border-bottom: 1px solid lightgray;
    width: 90%;
    margin: 10px;
  }

  .select-country {
    label {
      padding: 12px 0;
      font-size: 12px;
      color: #777777;
    }

    select {
      width: 100%;
      background: white;
      height: 48px;
      border: 1px solid #0f0f22;
      font-size: 14px;
      padding-left: 22px;
    }
  }

  .button-group {
    width: 100%;
    text-align: center;
    margin: 100px 20px;

    .button-primary {
      width: 80%;
    }
  }

  .container {
    width: 65%;
    min-width: 500px;

    h1 {
      text-align: center;
    }
  }

  > div {
    margin-top: 15px;
  }

  .names {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .input-with-label {
      width: 48%;
    }

    .years {
      display: inline-flex;
      margin: 0;
      justify-content: flex-end;

      .input-with-label {
        margin: 15px 9px;
        width: 40%;
      }
    }
  }

  .intro {
    input {
      height: 150px;
    }
  }

  .add-experience {
    font-weight: 700;
    text-decoration: underline;
    margin: 40px 0;
  }
}

.linkedin-signup__profile-image-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.linkedin-signup__profile-image {
  height: 126px;
  width: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
}

.linkedin-signup__uploader-button {
  .fileContainer {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .chooseFileButton {
    border-radius: 5px !important;
    font-family: gilroy;
    border: 2px solid $primary-blue;
    color: $primary-blue;
    border-radius: 2px;
    box-sizing: border-box;
    background: #fff;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
      background-color: $primary-blue;
      border: 2px solid $primary-blue;
      color: white !important;
      cursor: pointer;
      &.disabled {
        background: unset;
        cursor: default;
      }
    }
  }
}

.linkedin-signup__validation-error {
  display: inline-block;
  color: $error;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.linkedin-signup__button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.linkedin-signup__next-button {
  min-width: 300px;
  margin-top: 50px;
}
