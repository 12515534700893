$primary: #583372;
$secondary: #f7f7f7;
$light: #f3f7fa;
$contrastTextPrimary: rgb(255, 255, 255);
$dark: #b8eaf0;
:export {
  primary: $primary;
  secondary: $secondary;
  light: $light;
  contrastTextPrimary: $contrastTextPrimary;
  dark: $dark;
}
