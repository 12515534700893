.dashboard-title-component {
  display: flex;
  justify-content: space-between;
  margin: 0 0 22px 0;
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  max-width: 600px;
  gap: 20px;
  @media screen and (max-width: 950px) {
    font-size: 24px;
    max-width: unset;
    padding: 0 20px;
  }

  .dashboard-title-text {
    width: 100%;
    word-break: break-word;

    & > div {
      margin-top: 6px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.empty-page-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50vh;
  justify-items: center;
  @media screen and (max-width: 950px) {
    height: auto;
    padding-top: 50px;
  }
}

.not-found-image {
  width: 272px;
  height: 225px;
  justify-self: center;
  @media screen and (max-width: 950px) {
    width: 168px;
    height: 139px;
  }
}

.not-found-text {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  width: 450px;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
    width: 300px;
  }
}

.loading-button-container {
  width: 100%;
  @media screen and (max-width: 950px) {
    width: 328px;
    max-width: 100%;
  }
}

.tab-panel-requests-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
}

.community-requests-helper-text {
  display: inline-flex;
  width: 45vw;
  min-width: 300px;
  align-items: center;
  justify-content: space-between;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
  margin-bottom: 16px;
  @media screen and (max-width: 950px) {
    width: 80vw;
    font-size: 14px;
  }
}
