@import 'src/variables.scss';
@import '../../styling/palette.scss';
.atc-groupevent-hidden-text {
  position: absolute;
  visibility: hidden;
}

.atc-hidden-text {
  position: absolute;
  visibility: hidden;
}

.addToCalendarWrapperGroup {
  div:first-child {
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    margin-left: 6px;
    &:hover {
      text-decoration: none;
    }
  }
}
