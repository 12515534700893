@import 'src/variables.scss';

.volunteer-profile__upcoming-column-container {
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.volunteer-profile__single-column {
  flex-direction: column;
}

.volunteer-profile__profile-column {
  display: flex;
  flex-direction: column;
  flex: 1;

  .volunteer-profile-photo {
    flex-grow: 0;
    margin: 0 25px 0 0;
    display: grid;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 160px;
  }

  @media (max-width: 767px) {
    margin: 0 auto;
  }
}

.volunteer-profile__skill-tag {
  margin: 2px;
}

.volunteer-profile__details-column {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 84px;

  @media (max-width: 767px) {
    margin: 24px 0;
  }
}

.volunteer-profile__details-column--compact {
  margin: 24px 0;
}

.volunteer-profile__profile-picture {
  border-radius: 10px;
  box-shadow: 0px 1px 1px 0px #00000040;
}

.volunteer-profile__volunteer-name {
  font-size: 28px;
  margin: 0 0 12px;
  display: flex;
  align-items: center;
}

.volunteer-profile__you-text {
  padding: 3px 8px;
  margin-left: 10px;
  border-radius: 2px;
  color: white;
  font-size: 16px;
  background-color: $tag-grey;
  color: $text-grey;
}

.volunteer-profile__body-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.volunteer-profile__about-me-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.volunteer-profile__about-me {
  font-size: 14px;
  margin-bottom: 0;
}

.volunteer-profile__read-more-button {
  padding: 0;
}

.volunteer-profile__experience-text-container {
  margin-bottom: 14px;
  font-size: 14px;
}

.volunteer-profile__languages-container {
  display: flex;
}

.volunteer-profile__language {
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}

.volunteer-profile__linkedin-link {
  text-decoration: none;
  color: inherit;
  font-weight: 700;
  margin: 27px auto 0 0;
  display: inline-block;
}

.volunteer-profile__linkedin-text {
  text-decoration: none;
  color: #0274b1;

  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-top: -5px;
  }
}
