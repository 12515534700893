.login-signup {
  min-height: 60vh;
  padding: 20px 30px 0;

  @media (max-width: 1000px) {
    padding: 0;
  }
}

.login-signup__modal {
  max-width: 1300px;
  margin: 30px auto;
}
