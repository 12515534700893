@import 'src/variables.scss';

.container {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  max-width: 800px;
  min-width: 40%;
  padding: 30px 30px 10px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.title {
  margin: 0;
}
.subtitle {
  margin: 10px 0 0;
  font-weight: 500;
}
.error {
  color: $error;
}

.subContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.input {
  margin: 10px 0 0;
}

.button {
  margin: 10px 0 0;
}

.linkText {
  margin: 10px 0 0;
  align-self: center;
}

.link {
  color: $highlight-blue;
  text-decoration: none;
  margin: 20px 0;
  align-self: flex-start;
  font-weight: 600;
  &:hover,
  &.selected {
    font-weight: inherit;
    text-decoration: underline;
    font-weight: 600;
  }
}

.messageVisible {
  visibility: visible !important;
}

.message {
  color: white;
  padding: 5px 10px;
  visibility: hidden;
  align-self: center;
  border-radius: 3px;
  margin: 10px 0 0;
  font-weight: 600;
}

.errorMessage {
  background-color: $error;
}

.successMessage {
  background-color: $success;
}
