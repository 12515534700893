.account_details_information {
  display: inline-flex;
  column-gap: 2vw;
}

.linkedIn-button__container {
  background-color: primary;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.profileCompleted__container {
  font-size: x-small;
}

.delete-account__container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 2vh;
  font-family: 'Gilroy';
}
