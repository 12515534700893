@import 'src/variables.scss';

.sign-up-skills {
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 25px;
  padding: 20px;
  min-width: 80vw;
  max-width: 1000px;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 10px;
    margin-left: 0;
  }

  .arrow {
    margin-right: 17px;
    position: absolute;
    top: 8px;
    right: -4px;
    color: white;
  }

  .sub-skills {
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    max-width: 298px;
    flex-direction: column;
    padding-bottom: 10px;
    transition: all 0.6s ease;

    &-true {
      transition: all 0.6s ease;
      visibility: visible;
      opacity: 1;
    }

    div {
      display: flex;
      padding: 10px 10px;
      font-family: 'Gilroy';
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
      input {
        cursor: pointer;
      }
      label {
        cursor: pointer;
        text-align: left;

        margin-left: 12px;
      }
    }
  }

  .select-skill {
    width: auto;
    position: relative;
    margin: 3px 2px;
    cursor: pointer;

    span {
      margin-top: 12px;
      font-family: 'Gilroy';
      font-size: 18px;
      line-height: 20px;
      position: absolute;
      top: 0px;
      left: 11px;
      max-width: 200px;
      color: $dark-grey;
      font-weight: 700;
      text-align: left;
    }

    input {
      margin-top: 12px;
      position: absolute;
      left: 251px;
    }
  }
}
