.group-event-agenda-card__card {
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  padding: 10px 20px 20px;
  margin-top: 20px;
  border-radius: 2px;
  @media screen and (max-width: 750px) {
    padding: 0;
  }

  .group-event-agenda__description {
    margin-bottom: 20px;
  }
}
.group-event-agenda-card-alert {
  margin-top: 20px;
  font-family: 'Gilroy';
}
.group-event-agenda-card__card-row {
  display: flex;
  font-family: 'Gilroy';
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
  &:first-of-type {
    margin-top: 0;
  }
}

.group-event-agenda-card__card-row-1 {
  max-width: 75%;
  a {
    font-weight: 600;
  }
}
.group-event-agenda-card__card-row-2 {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}
