@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
  padding: 0 50px;
  text-align: left;
  white-space: pre-line;
}
.availabilityInputDetails {
  display: inline-flex;
  width: 100%;
  column-gap: 20px;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.titleContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.titleTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid $shade-grey;
  padding: 0 0 24px 0;
  max-width: 1200px;
  flex: 1;
}

.title {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.subtitle {
  color: $light-green;
  margin: 8px 0 0 0;
  font-size: 28px;
  font-weight: 700;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  flex: 1;
  padding: 0 0 33px 0;
  border-bottom: 1px solid $tag-grey;
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
}

.thankYouText {
  margin-top: 24px;
}

.availabilityTitle {
  position: relative;
  font-size: 24px;
}

.businessHoursText {
  margin-top: 15px;
}

.datePickerContainer {
  display: flex;
  width: 400px;
  @include media('<phone') {
    width: 100%;
  }
}

.datePicker {
  min-width: 300px;
}

.errorText {
  color: $error;
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
}

.recordingCheckboxLabel {
  margin-top: 39px;
  display: flex;
}

.buttonContainer {
  width: 100%;
  text-align: right;
  margin-top: 72px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  -webkit-position: sticky;
  bottom: 0px;
  padding: 20px 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.7);
  button {
    max-width: 500px;
    display: flex;
    justify-content: center;
    padding: 12px 20px;
    pointer-events: auto;
  }
}

.submitButton {
  min-width: 300px;
}

.recordingCheckbox {
  margin-right: 11px;
}

.loadingContainer {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  height: 200px;
  width: 200px;
}
