//@import "~include-media/dist/_include-media.scss";

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Gilroy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
}

input[type='checkbox'] {
  -webkit-transform: scale(2, 2);
  transform: scale(1.2, 1.2);
}

// CookieBot CSS customisation
#CybotCookiebotDialog {
  border: None !important;
}

#CybotCookiebotDialog * {
  font-family: 'Gilroy' !important;
}
