.dashboard-bookings-container {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
}

.tab-panel-bookings-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  @media screen and (max-width: 950px) {
    margin-left: -20px;
  }
}
