@import 'src/variables.scss';

.share_container {
  position: relative;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 22px;
  fill: $primary-blue;
}

.shareTxt {
  font-weight: bold;
  margin-left: 12px;
}

.share_text_container {
  display: none;
  position: absolute;
  top: 30%;
  transform: translate(-50%);
  z-index: 30;
  width: 390px;
  word-wrap: break-word;
  height: auto;
  padding: 0 10px;
  border-radius: 3px;
  background: white;
  padding-bottom: 30px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 950px) {
    width: 85vw;
    height: auto;
  }
}

.share_text_container_modal {
  display: none;
  position: absolute;
  top: 30%;
  right: 0;
  z-index: 30;
  width: 390px;
  word-wrap: break-word;
  height: auto;
  padding: 0 10px;
  border-radius: 3px;
  background: white;
  padding-bottom: 30px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 950px) {
    width: 85vw;
    height: auto;
  }
}

.share_text_container_visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.share_text_container_visible_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.text {
  font-weight: 500;
  font-size: 16px;
  width: auto;
  text-align: center;
  @media screen and (max-width: 950px) {
    margin-top: 20px;
  }
}

.linkText {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  width: auto;
  text-align: center;
}

.shareLinkPointer {
  position: absolute;
  left: 49%;
  transform: translate(-50%);
  bottom: 147px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  padding: 0 15px;
  z-index: 40;
}

.shareLinkButton {
  display: flex;
  margin-top: 12px;
}

.closeIcon {
  width: 100%;
  height: auto;
  text-align: right;
  margin-right: 30px;
  margin-top: 10px;
}

.share-link-paper-container {
  width: 150px;
  position: absolute;
  left: 49%;
  transform: translate(-50%);
  bottom: 147px;
}
