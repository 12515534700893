@import '../../../styling/palette.scss';

.skills__container {
  margin-top: 5px;
  width: 600px;
  background-color: $secondary;
  padding-bottom: 10px;
}
.categories__container {
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 40px;
  padding: 10px;
}
.categories__column-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.category-input {
  font-family: 'Gilroy';
  display: inline-flex;
  flex-direction: column;
}
.category-input_name {
  display: inline-flex;
  column-gap: 2px;
  font-family: 'Gilroy';
  font-size: 17px;
}

@media only screen and (max-width: 950px) {
  .skills__container {
    background-color: $secondary;
    width: 320px;
  }
  .categories__container {
    column-gap: 40px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    padding: 10px;
  }
}
