@import 'src/variables.scss';

.group-event-confirmation__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
  padding: 0 50px;
  text-align: left;
  white-space: pre-line;
}

.group-event-confirmation__title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.group-event-confirmation__title-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid $shade-grey;
  padding: 0 0 24px 0;
  max-width: 1200px;
  flex: 1;
}

.group-event-confirmation__title {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.group-event-confirmation__subtitle {
  color: $light-green;
  margin: 8px 0 0 0;
  font-size: 28px;
  font-weight: 700;
}

.group-event-confirmation__body-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  flex: 1;
  padding: 0 0 33px 0;
  border-bottom: 1px solid $tag-grey;
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
}

.group-event-confirmation__body-text {
  margin-top: 24px;
}
.group-event-confirmation__body-subtitle {
  margin: 32px 0 0 0;
}
.group-event-confirmation__prepare-text {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.group-event-confirmation__recording-checkbox-label {
  margin-top: 39px;
  max-width: 675px;
  display: flex;
}

.group-event-confirmation__atc-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.group-event-confirmation__atc-button {
  width: 300px;
}

.group-event-confirmation__briefing-button {
  margin-left: 15px;
  font-weight: 600;
}

.group-event-confirmation__recording-checkbox {
  margin-right: 11px;
}

.group-event-confirmation__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.group-event-confirmation__loading-spinner {
  height: 200px;
  width: 200px;
}
