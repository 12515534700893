@import 'src/variables.scss';

.propose-another-call__choose-times {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .c-container {
    display: flex;
    flex-direction: column;
    min-width: 209px;
    align-items: center;
    justify-content: center;

    .date-container {
      display: flex;
      .calendar {
        color: $background-blue;
        padding-right: 2px;
      }
    }

    .react-datepicker__input-container input {
      font-size: 14px;
    }
  }

  .button-add-date {
    font-family: 'Gilroy';
    font-weight: 700;
    padding: 10px 10px;
    background: $tag-grey;
    border-radius: 2px;
    border: none;
    cursor: pointer;
  }
}

.propose-another-call__container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.propose-another-call__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.propose-another-call__choose-call-provider-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 17px;
  margin-bottom: 17px;
  h5 {
    margin: 0px;
  }
}

.propose-another-call__choose-call-provider-dropdown {
  min-width: 400px;
}

.propose-another-call__provider-call-tooltip {
  position: relative;
  margin-left: 5px;
  z-index: 0;
}

.propose-another-call__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.propose-another-call__loading-spinner {
  height: 200px;
  width: 200px;
}

.propose-another-call__call-url-input {
  margin-top: 25px;
}

.propose-another-call__call-url-input-text {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;
}

.propose-another-call__error-hidden {
  visibility: hidden;
}
