.unselected-message-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .unselected-message-image {
    width: 278px;
  }

  .unselected-message-title {
    margin-bottom: 8px;
    margin-top: 24px;
    font-family: 'Gilroy';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    text-align: center;
    color: #000;
  }

  .unselected-message-subtitle {
    margin-bottom: 12px;
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.34px;
    text-align: center;
    color: #000;
    width: 308px;
  }
}
