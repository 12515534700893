@import 'src/variables.scss';

.profile-photo-card {
  width: 58px;
  margin-top: -20px;
  height: 58px;
  border-radius: 50%;
  @media screen and (max-width: 950px) {
    width: 48px;
    height: 48px;
    margin-top: -0;
  }
}

.request-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  font-family: Gilroy;
  padding-left: 4px;
}
.request-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  @media screen and (max-width: 950px) {
    width: 160px;
    overflow-wrap: break-word;
  }
}
.request-paper-button-wrapper {
  display: grid;
  grid-template-columns: min-content min-content min-content min-content;
  @media screen and (max-width: 950px) {
    grid-template-columns: auto;
  }
}
.request-paper-time-slots {
  margin-top: -5px;
  @media screen and (max-width: 950px) {
    margin-left: -70px;
  }
}
.request-paper-slots {
  padding-top: 15px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  @media screen and (max-width: 950px) {
    margin-left: -70px;
  }
}
.request-help-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.28px;
  text-align: left;
  margin-bottom: 10px;
  color: #777;
}
.request-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.requested-dashboard-button-container {
  width: 150px;
  @media screen and (max-width: 950px) {
    width: calc(min(75vw, 280px));
    margin-left: -70px;
  }
}

.rectangle-icon {
  display: flex;
  background-image: linear-gradient(92deg, #ffb879 10%, #f2c94c 100%);
  justify-content: flex-end;
  align-items: flex-end;
  width: 100px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.rectangle-icon:after {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}

.business-health-icon {
  width: 80px;
  height: 25px;
  margin-right: 15px;
  padding: 4px 8px;
  border-radius: 50px;
  background-color: #ffd5a6;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  color: #000;
}

.business-health-colors {
  display: inline-flex;
  width: 100%;
}

.color-box {
  padding: 5px;
  width: 20%;
  height: 29px;
  text-align: center;
  font-family: Gilroy;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  color: #000;
}
.business-health-tooltip {
  display: inline-flex;
  flex-direction: row;
  column-gap: 2px;
  justify-content: center;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
}

.health-tooltip-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
}

.health-descriptions {
  display: inline-flex;
  justify-content: space-between;
}

.health-tooltip-title {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #fff;
}

.request-card__container {
  display: grid;
  margin-top: -25px;
  grid-template-columns: 80px auto;
  padding-left: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: 950px) {
    grid-template-columns: 50px auto;
    column-gap: 10px;
    margin-top: 10px;
  }
}
.requested-paper-box {
  width: 603px;
  min-height: 256px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;

  @media screen and (max-width: 950px) {
    width: 328px;
    height: 420px;
    max-width: 100%;
  }
}
