@import 'src/variables.scss';

$nav-height__medium: 68px;
$nav-height__small: 48px;
.nav-no-profile {
  width: 100%;
  height: '55px';
  display: inline-flex;
  justify-content: space-between;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 25;

  height: $nav-height__medium;
  @media (max-width: 950px) {
    height: $nav-height__small;
  }

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    @media (max-width: 950px) {
      padding: 0;
    }

    .quick-link {
      margin-right: 55px;
      padding: 10px 0;
      font-size: 17px;
      font-weight: 700;
      cursor: pointer;
      line-height: 24px;
      text-decoration-skip-ink: none;
      white-space: pre;
    }

    .quick-link:hover {
      text-decoration: underline $highlight-green;
      text-underline-offset: 2px;
      text-decoration-thickness: 2px;
    }

    .profile-pic {
      margin-right: 10px;
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
        margin: auto;
        border: 1px solid;
        top: 32px;
        object-fit: cover;
      }

      @media (max-width: 950px) {
        margin-right: 0;
      }
    }

    .nav__logo {
      display: block;
      width: 150px;
      max-height: 60px;
      @media (max-width: 950px) {
        height: 30px;
      }
    }

    .nav-link-container {
      display: flex;
      align-items: center;
      padding: 10px 0;
      @media (max-width: 950px) {
        padding: 0;
        height: $nav-height__small;
      }
    }

    .nav-link-separator {
      height: 120px;
      width: 1px;
      background-color: #a3a3a3;
      margin: -21px 44px 41px -11px;
    }

    .nav-link {
      font-size: 18px;
      font-weight: bold;
      color: #0f0f22;

      .name {
        right: 106px;
        top: 30px;
        @media (max-width: 950px) {
          font-size: 10px;
        }
      }

      a {
        color: #0f0f22;
        text-decoration: none;
      }

      span {
        cursor: pointer;
      }
    }
  }
}

.nav__logged-out-link-container {
  position: relative;
  @media (max-width: 950px) {
    padding: 10px;
  }
  .nav__logged-out-link-wrapper {
    display: flex;
    flex-direction: flex-end;
    align-items: center;
    @media (max-width: 950px) {
      position: absolute;
      right: 0;
      flex-direction: column;
      align-items: center;
      padding: 0 15px 15px 15px;
      background: #fff;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      top: 100%;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
    }
  }
}

.nav__sizing-placeholder {
  width: 100vw;
  height: $nav-height__medium;
  @media (max-width: 950px) {
    height: $nav-height__small;
  }
}

.nav__logged-out-link {
  @media (max-width: 950px) {
    margin: 20px 0 0 !important;
    &:first-child {
      margin: 0 !important;
    }
  }
}

.nav__login-button {
  margin-right: 10px;
  min-width: 100px;
  border-radius: 2px;
  @media (max-width: 950px) {
    width: 100%;
  }
}

.nav__signup-highlight {
  color: $light-green;
  @media (max-width: 950px) {
    display: none;
  }
}

.nav__profile-wrapper {
  display: flex;
  align-items: center;
  padding-left: 40px;

  @media (max-width: 950px) {
    padding-left: 0;
  }
}

.nav__pic-and-name {
  display: flex;
  align-items: center;
  margin-left: 15px;

  @media (max-width: 950px) {
    margin-left: 8px;
  }
}

.nav__burger-menu-container {
  position: relative;
  display: flex;
  border-left: 1px solid white;

  .menu-box {
    position: absolute;
    top: 90%;
    right: 0;
    padding-right: 15px;
    pointer-events: none;
    border-radius: 2px;
    pointer-events: initial;
    background: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  }
}

.nav__timezone-indicator {
  margin-right: 15px;
  justify-self: stretch;

  span {
    cursor: default !important;
  }

  @media (max-width: 950px) {
    margin-right: 8px;
  }
}

.nav__faq-indicator {
  margin-right: 15px;
  justify-self: stretch;

  span {
    cursor: default !important;
  }

  @media (max-width: 950px) {
    margin-right: 8px;
  }
}

.nav__messaging-indicator {
  justify-self: stretch;
}

.nav__share-link-indicator {
  margin-right: 15px;
  justify-self: stretch;
  border-radius: 50%;

  span {
    cursor: default !important;
  }

  @media (max-width: 950px) {
    margin-right: 8px;
  }
}

.nav__menu-box-link {
  margin: 10px;
  cursor: pointer;
}
.nav__hidden {
  visibility: hidden;
}

.nav__shadow {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}
