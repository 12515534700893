@import './sizeVariables';
.popup-text {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;

  @media screen and (max-width: 750px) {
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 132.5%; /* 17.225px */
    letter-spacing: 0.26px;
  }
}
.attachment-popup-title {
  color: #000;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: $mobileBreakpoint) {
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.16px;
  }
}

.library-attachment-popup-title {
  color: #000;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: $mobileBreakpoint) {
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
    letter-spacing: 0.16px;
  }
}

.mini-files-container {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  width: 580px;
  gap: 20px; /* Specifies both row-gap and column-gap in modern browsers */

  @media screen and (max-width: $mobileBreakpoint) {
    display: grid;
    column-gap: 0;
    grid-template-columns: 150px 150px;
    width: 321px;
    flex-shrink: 0;
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    display: inline-flex;
    width: 100%;
    align-content: center;
    flex-wrap: wrap;
  }
}

.mini-library-scroll {
  height: 303px;
  align-self: center;
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
}

.mini-file-container {
  width: 130px;
  height: 105.625px;
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
  letter-spacing: 0.13px;
  border-radius: 8.125px;
  background: var(--secondary-light, #f3f7fa);
  justify-content: center;
  display: inline-flex;
  row-gap: 5px;
  flex-direction: column;
  background-color: #f2f2f2;
  @media screen and (max-width: $mobileBreakpoint) {
    width: 120px;
    height: 100px;
    flex-shrink: 0;
    row-gap: 4px;
    border-radius: 10px;
    padding-top: 13px;
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    width: 340px;
    height: 100px;
    flex-shrink: 0;
    row-gap: 4px;
    border-radius: 10px;
    padding-top: 13px;
  }
}

.mini-date-file-format {
  color: #000;
  font-family: Gilroy;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
  margin-top: 10px;
}

.mini-file-container.selected {
  background-color: #c4ced8; /* Example styling for selected files */
}

.submit-container {
  display: inline-flex;
  width: 100%;
  margin-top: -40px;
  padding-right: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
  @media screen and (max-width: $mobileBreakpoint) {
    margin-top: 0;
    justify-content: center;
    margin-top: 10px;
  }
}
