@import '../../../styling/palette.scss';

.mentoring-preferences__container {
  display: inline-flex;

text-align: left;
max-width: 600px;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 40px;
}
.mentoring-custom-hours-input {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
}
.mentoring-preferences-button {
  display: inline-flex;
  color: white;
  border-radius: 10px;
  font-size: small;
  padding-left: 5px;
  margin: 5px;
  height: fit-content;
  align-items: center;
  justify-items: center;
}

.mentoring-preferences-title {
  font-weight: bold;
  margin-top: 20px;
  font-family: 'Gilroy';
  font-size: 17px;
}

.update-preferences-buttons {
  margin-top: 3vh;
  display: inline-flex;
  column-gap: 2vw;
}

.datePicker-time {
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
}
.datePicker__container {
  display: inline-flex;
  column-gap: 30px;
}
.datePicker {
  width: 120px;
  height: 40px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
}

@media only screen and (max-width: 950px) {
  .mentoring-preferences__container {
    margin-top: 20px;
    width: 90%;
  }
}
