.pastExperiences__container {
  font-family: 'Gilroy';
}
.pastExperiences__entry {
  margin: 10px;
}
.pastExperiences__entry-role {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 4px;
  text-align: left;
  color: #000;
}

.pastExperiences__entry-employer {
  font-weight: 500;
  margin-left: 10px;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #000;
  margin: 4px 71px 4px 20px;
}
.pastExperiences__entry-date {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  margin: 4px 71px 4px 20px;
}
.pastExperiences__entry-form {
  display: inline-flex;
  flex-direction: column;
  row-gap: 2vh;
  width: fit-content;
  padding: 0;
}
.pastExperiences__entry-form-entry-row {
  display: inline-flex;
  column-gap: 1vw;
}

.past-experiences-form__container {
  width: fit-content;
}

@media only screen and (max-width: 950px) {
  .past-experiences-form__container {
    display: inline-flex;
    flex-direction: column;
  }

  .pastExperiences__entry-form-entry-row {
    display: grid;
    grid-template-columns: 1fr;
  }

  .pastExperiences__item-form {
    display: inline-flex;
    flex-direction: column;
  }
  .pastExperiences__entry {
    margin: 0;
  }
  .pastExperiences__entry-form {
    padding: 0;
  }
}

.current-year-form-select {
  display: flex;
  flex-direction: column;
}
