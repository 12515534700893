.actionplan-title-page {
  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 30.8px */
  letter-spacing: 0.28px;
}
.actionplan-title-page-mobile {
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.actionplan-topbar {
  display: inline-flex;
  width: 800px;
  justify-content: space-between;
}
.actionplan-topbar-mobile {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  row-gap: 30px;
  align-items: center;
  justify-content: center;
}

.actionplan-tab-text {
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.51px;
  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
}

.actionplan-start-image {
  margin-top: 20px;
}

.plan-overview {
  display: inline-flex;
  justify-content: space-between;
  width: 850px;
  max-width: 95%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

.plan-overview-mobile {
  display: inline-flex;
  flex-direction: column;
  width: 300px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.plan-overview-container-mobile {
  display: inline-flex;
  flex-direction: column;
  min-height: 50vh;
  row-gap: 15px;
}

.plan-overview-functions {
  color: #777;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
  display: inline-flex;
  align-items: center;
  column-gap: 16px;
  padding-right: 20px;
}
.plan-overview-functions-mobile {
  color: #777;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
  display: inline-flex;
  align-items: center;
  column-gap: 20px;
  padding-right: 10px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.action-plan-date {
  width: 90px;
}
.plan-overview-title {
  color: #333;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;
  align-self: center;
  margin-left: 20px;
  max-width: 500px;
  cursor: pointer;
}

.plan-overview-title-mobile {
  color: #333;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;
  align-self: center;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
}

.table-date-time {
  color: #333;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
  margin-left: 20px;
  margin-top: 8px;
}
.center-in-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.action-plan-search {
  width: 500px;
  max-width: 70vw;
  height: 32px;
  border-radius: 8px;
  border: solid 1px #a3a3a3;
  padding: 0 16px;
  margin-left: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}
.plan-overview-date-mobile {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  padding-left: 10px;
  font-size: 14px;
  font-family: Gilroy;
}

.error-message {
  color: #ff5f5f;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
}
