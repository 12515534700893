.container__account-page {
  font-family: 'Gilroy';
  display: grid;
  margin: 30px;
  grid-template-columns: 1fr 4fr;
  column-gap: 3vw;
  @media only screen and (max-width: 950px) {
    display: inline-flex;
    flex-direction: column;
    width: 350px;
    align-items: left;
    padding-left: 5px;
    margin: 15px;
    grid-template-columns: 1fr;
    row-gap: 2vh;
  }
}

.container__option-tabs {
  background-color: #f0f8ff;
  border-radius: 15px;
  padding: 5px;
  max-width: 400px;
}

.tab__container {
  display: inline-flex;
  justify-content: space-between;
}

.profile-completion__container {
  padding: 15px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.fileContainer {
  background-color: transparent;
  box-shadow: none;
  border-radius: 5px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  transition: all 0.3s ease-in;
}
