@import 'src/variables.scss';

.recording-card__container {
  max-width: 750px;
  height: 486px;
  width: 100%;
  @media screen and (max-width: 950px) {
    width: 350px;
    height: 420px;
    margin-left: -20px;
  }
}

#booking-recordings {
  width: 740px;
  height: 350px;
  background-color: black;
  @media screen and (max-width: 950px) {
    width: 310px;
    height: 300px;
  }
}

.recording-paper-topic {
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
}

.recording-paper-time {
  display: inline-flex;
  column-gap: 4px;
  align-items: center;
  margin: 0 0 0 4px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.recording-card-title {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: left;
  color: #000;
}

.recording-card-paper-box {
  width: 800px;
  height: 486px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  @media screen and (max-width: 950px) {
    width: 350px;
    height: 420px;
  }
}
