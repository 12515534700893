@import 'src/variables.scss';

.Toastify__toast-body {
  font-weight: 700 !important;
  font-family: 'Gilroy' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App {
  font-family: 'Gilroy';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.error {
  color: $error;
  font-weight: 700;
}
.error-message-attachment {
  color: $error;
  font-weight: 700;
  font-size: 14px;
}
.__react_component_tooltip {
  max-width: 250px;

  opacity: 1 !important;
  color: white !important;
  font-weight: 700;
  font-family: 'Gilroy';
  text-align: left !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
