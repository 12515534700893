@import 'src/variables.scss';

.newsletter {
  margin: 100px;
  .error {
    color: $error;
    font-weight: 700;
  }

  div {
    margin: 25px 0;
  }
}

.error-state {
  color: red !important;
  font-weight: 700 !important;
  font-size: 20px;
  margin-top: 10px;
}

.back-link {
  font-family: 'Gilroy';
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  padding-top: 25px;
  cursor: pointer;
  text-align: center;
  color: #a3a3a3;
}

.profile-create {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  .error {
    color: $error;
    font-weight: 700;
  }

  padding: 20px;
  font-family: 'Gilroy';

  .tag {
    margin: 9px;
  }

  .back-link {
    font-family: 'Gilroy';
    font-size: 14px;
    line-height: 16px;
    color: $light-grey;
    text-decoration-line: underline;
    padding-top: 25px;
    cursor: pointer;

    color: #a3a3a3;
  }

  .newsletter {
    margin: 100px;
  }

  .availability {
    ul {
      display: flex;
      flex-direction: column;
      label {
        margin: 5px 0;
      }
    }
  }

  .select-country {
    width: 500px;
    @media screen and (max-width: 600px) {
      width: 90vw;
    }

    margin-top: 35px;

    label {
      padding: 12px 0;
    }

    select {
      width: 100%;
      height: 50px;
    }
  }

  .input-container {
    max-width: 470px;
    margin: auto;
    text-align: left;

    button {
      width: 100%;
    }
  }

  .radio-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;

    .gender-label {
      padding: 12px 0;
    }

    .ethnicity-label {
      padding: 12px 0;
    }

    .gender-options {
      display: flex;
      font-family: 'Gilroy';
      flex-direction: column;
      row-gap: 10px;
    }
  }
  .profile-form-fields {
    display: inline-flex;
    flex-direction: column;
    row-gap: 20px;
    width: 500px;
    @media screen and (max-width: 600px) {
      width: 90vw;
    }
  }
  .select-ethnicity {
    width: 500px;
    @media screen and (max-width: 600px) {
      width: 90vw;
    }
    font-family: 'Gilroy';
    display: flex;
    flex-direction: column;

    label {
      padding: 12px 0;
    }

    select {
      height: 48px;
      border: 1px solid #0f0f22;
      font-size: 14px;
      padding-left: 22px;
    }

    button {
      width: 100%;
    }
  }
}
.input-with-label {
  display: flex;
  flex-direction: column;
}

.profile-create__custom-availability-input {
  width: 60px;
}

.profile-create__timezone-text {
  font-size: 18px;
  color: #777777;
  margin: 0 12px 0 0;
}

.profile-create__timezone-container {
  display: inline-flex;
  flex-direction: column;
}

.profile-create__tooltip {
  position: relative;
  margin-left: 5px;
}
.profile-create__timezone-tooltip {
  position: relative;
  margin-left: 5px;
}
.profile-create__button-container {
  align-self: center;
}

.profile-create__next-button {
  min-width: 300px;
  margin-top: 50px;
}

.profile-create__photo-container {
  margin-bottom: 20px;
  max-width: 300px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.profile-create__skills-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile-create__next-button {
  margin-top: 20px;
}

.profile-create__validation-error {
  display: inline-block;
  color: $error;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.profile-picture-volunteer {
  align-self: center;
  min-width: 148px;
  min-height: 148px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.disability-label-italic {
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-style: italic;
}

.disability-label {
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;
  font-size: 16px;


}
