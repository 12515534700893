.dashboard-messaging-container {
  display: inline-flex;
  min-width: 100%;
  flex-direction: column;
}

.dashboard-messaging-title-wrapper {
  min-width: 383px;
  max-width: 383px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  box-sizing: border-box;

  @media screen and (max-width: 950px) {
    min-width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin-top: 24px;
    align-items: flex-start;
    flex-direction: column;
  }

  .dashboard-messaging-title-component {
    font-family: 'Gilroy';
    font-size: 28px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: 0.28px;
    color: #333333;
  }

  .dashboard-messaging-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 950px) {
      margin-top: 12px;
      width: 100%;
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      height: 22px;
      width: 22px;
      padding: 0;
    }

    .dashboard-messaging-input-container {
      width: 100%;
      height: 32px;
      border-radius: 8px;
      border: solid 1px #a3a3a3;
      padding: 0 16px;
      margin-left: 8px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;

      .dashboard-messaging-clear-button {
        position: absolute;
        right: 16px;
        cursor: pointer;
        margin-top: 4px;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;

        font-family: 'Gilroy';
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.34px;

        &::placeholder {
          color: #777;
        }
      }
    }
  }
}

.messaging-wrapper {
  width: 1000px;
  margin-right: 40px;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin-right: 0;
  }

  .messaging-left-panel {
    height: 100%;
    min-width: 383px;
    max-width: 383px;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media screen and (max-width: 950px) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .messaging-right-panel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
  }
}
