.profile_form {
  font-family: 'Gilroy';
  display: inline-flex;
  flex-direction: column;
}

.profile__container {
  display: grid;
  grid-template-columns: 1fr;
}

.profile_summary__container {
  margin-top: 60px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 3fr 1fr;
}

.profile-picture__container {
  display: inline-flex;
  flex-direction: column;
}
.profile-picture {
  width: 138px;
  height: 138px;
  flex-grow: 0;
  margin: 0 25px 0 0;
  padding: 100px 1px 1px 100px;
  position: relative;
  display: grid;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.linkedIn-button {
  align-content: center;
}
.summary-text {
  display: flex;
  flex-direction: column;
}

.volunteer-name {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Gilroy';
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: left;
  color: #000;
}

.volunteer-job {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Gilroy';
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
}

.volunteer-join-date {
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: 'Gilroy';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #000;
}
.profile-input__container {
  align-items: center;
  display: inline-flex;
}

.profile-input_name {
  font-family: 'Gilroy';
  flex-grow: 0;
  margin: 24px 5px 4px 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
}

.profile-input_text {
  white-space: pre-wrap;
  font-family: 'Gilroy';
  margin: 4px 8px 0 0;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
}

.add_entry_profile {
  margin: 0 0 0 4px;
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #2f80ed;
}

.update-profile-buttons {
  display: inline-flex;
}

@media only screen and (max-width: 950px) {
  .profile_summary__container {
    justify-items: left;
    display: grid;
    row-gap: 0px;
    margin-top: 20px;
    grid-template-columns: auto;
  }
  .summary-text {
    display: flex;
    flex-direction: column;
  }

  .profile-picture {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
