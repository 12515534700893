@use 'sass:color';
@import 'src/variables.scss';
@import '../../styling/palette.scss';

.nps-score {
  margin-top: 62px;
  display: flex;
  align-items: center;
  overflow: hidden;
  overscroll-behavior-x: contain;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }

  .info-text {
    margin-top: 17px;
    margin-bottom: 17px;
    color: #777777;
    font-weight: 700;
  }

  label {
    color: #333333 !important;
    text-align: left;
  }

  .checkbox {
    text-align: left;
    input {
      margin-right: 12px;
    }
  }
}

.nps-score__mobile-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  @media screen and (max-width: 767px) {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    padding: 5px 15px;
    background-color: white;
    margin-top: 10px;
  }
}
.nps-score__title-container {
  @media screen and (max-width: 767px) {
    // padding-top: 132px;
  }
}

.nps-score__grid-container {
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
}

.nps-score__title {
  margin: 0;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    font-weight: 500;
  }
}

.nps-score__error {
  color: $error;
  font-weight: 700;
  font-size: 25px;
  margin-top: 0;
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
}

.nps-score__container {
  display: flex;
  flex-direction: column;
  max-width: 745px;
  margin: 0 20px 20px 20px;
  @media screen and (max-width: 767px) {
    margin: 0;
    background-color: #f7f9fb;
    min-height: 85vh;
  }
}

.nps-score__grid-descriptor-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nps-score__button-container {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 17px 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 17px 0 17px;
    z-index: 15;
  }
}

.nps-score__button {
  min-width: 300px;

  @media screen and (max-width: 767px) {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 18px;
  }
}

.scoring-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 17px;
  padding: 17px 0;

  div {
    padding: 13px 24px;
    border: black solid 1px;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
      background: $error;
    }
  }
}

.nps-score__thankyou-container,
.nps-score__feedbackalreadygiven-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 745px) {
    padding: 15px;
  }
}

.nps_score__thankyou-inner-container {
  max-width: 745px;
  display: flex;
  justify-content: flex-start;
}

.nps_score__thankyou,
.nps_score__feedbackalreadygiven {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    width: 100%;
    text-align: center;
  }

  p {
    margin: 16px 0 26px;
    text-align: left;
  }
}

.scoring-grid__mobile-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    background-color: white;
  }
}

.scoring-grid__slider-explainer {
  font-size: 14px;
  color: $dark-shade-grey;
}

.scoring-grid__slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 5px;

  .carousel__slide--hidden {
    opacity: 0.4;
    filter: alpha(opacity=50);
  }
}

.scoring-grid__score-slide--focused {
  opacity: 1;
  filter: alpha(opacity=100);
}

.scoring-grid__slider {
  overflow: visible !important;
  width: 70px;
  height: 70px;
}

.scoring-grid__selected-score {
  position: absolute;
  left: 50%;
  right: 50%;
  width: 70px;
  height: 70px;
  transform: translate(-50%);
  z-index: 10;
  pointer-events: none;
  border: 1px black solid;
}

.scoring-grid__score-pointer {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -12px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
}
.scoring-grid__arrow-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.scoring-grid__arrow-button {
  padding: 0;
  border: none;
  text-decoration: none;
  background-color: transparent;
  min-width: 44px;
  min-height: 44px;
}

.scoring-grid__slide-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
}

.nps-score__feedback-container {
  padding-bottom: 18px;

  @media screen and (max-width: 767px) {
    height: 50vh;
  }
}

.nps-score__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nps-score__loading-spinner {
  height: 200px;
  width: 200px;
}

.nps-score_hidden {
  visibility: hidden;
}

.nps-score__text-area {
  margin: 20px 0;
  width: 100%;
  max-height: 180px;
  transition: max-height 400ms ease-out;
  overflow: hidden;
}

.nps-score__text-area--collapsed {
  max-height: 0;
}

.nps-score__followup-subtitle {
  margin: 60px 0 0 !important;
}

.nps-score__followup-radio-container {
  display: flex;
  @media screen and (max-width: 745px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.nps_score__followup-radio {
  margin-left: 27px;

  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 745px) {
    margin-top: 10px;
    margin-left: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.nps-score__followup-button {
  width: 200px;
  @media screen and (max-width: 745px) {
    width: auto;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.nps-score__followup-thankyou-buttons {
  margin-top: 50px;
  justify-content: center !important;
}

.nps-score__thankyou-button-container,
.nps-score__score__feedbackalreadygiven-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 745px) {
    flex-direction: column;
    background: white;
    padding: 10px;
  }
}

.nps-score__call_did_not_happen {
  margin: 20px 0 0 10px;
}

.nps-tagged-tooltip {
  position: relative;
  cursor: pointer;
}

.nps-score__trust-container {
  width: 100%;
  margin: 0 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nps-score__trust-container-margin {
  margin: 20px 0 0;
}
