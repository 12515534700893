@import 'src/variables.scss';

.resources-container {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;

  @media screen and (max-width: 950px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .resources-title {
    margin-bottom: 22px;
    font-family: Gilroy;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.28px;
    color: #333;
  }

  .resources-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;

    .resources-left-col {
      min-width: 28%;
      max-width: 28%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 950px) {
        display: none;
      }

      .resources-label {
        margin-bottom: 12px;
        font-family: Gilroy;
        font-size: 18px;
        font-weight: 500;
        color: #333;
      }

      .resources-content-type-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 24px;

        .resources-content-type-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          cursor: pointer;

          .resouces-content-type-text {
            font-family: Gilroy;
            font-size: 16px;
            font-weight: 500;
            color: #333;
          }

          .resources-content-type-text-selected {
            font-weight: bold;
          }
        }
      }

      .resources-learning-input {
        width: 100%;
        height: 40px;
        border: 1px solid #333;
        border-radius: 9px;
        padding: 0 16px;
        outline: none;

        font-family: Gilroy;
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }

      .resources-selec-all-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        cursor: pointer;
        margin: 24px 0;

        .resouces-select-all-text {
          font-family: Gilroy;
          font-size: 16px;
          font-weight: 500;
          color: #333;
        }

        .resources-select-all-text-selected {
          font-weight: bold;
        }
      }
    }

    .resources-right-col {
      width: 100%;

      .resources-right-input-container {
        display: flex;
        align-items: center;
        gap: 16px;

        .resources-mobile-filters {
          @media screen and (min-width: 950px) {
            display: none;
          }
        }

        .resources-text-input {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          border: 1px solid #333;
          border-radius: 9px;
          padding: 0 16px;
          gap: 8px;

          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;

            font-family: Gilroy;
            font-size: 16px;
            font-weight: 500;
            color: #333;
          }
        }
      }

      .resources-grid-container {
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @media screen and (max-width: 950px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .resources-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }
    }
  }
}
