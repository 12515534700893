@import 'src/variables.scss';

.sign-up {
  font-family: 'Gilroy';

  .text-input-with-label {
    padding-top: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

  .input-container {
    text-align: left;
    display: inline-flex;
    flex-direction: column;
    min-width: 360px;
    width: 50%;
  }

  .linked-in-button {
    width: 481px;
    height: 52px;
    background: $linked-in-blue;
    font-family: 'Gilroy';
    font-size: 18px;
    color: white;
    position: relative;
    cursor: pointer;
    img {
      position: absolute;
      left: 85px;
      top: 2px;
      width: 37px;
    }
  }

  .error {
    color: red;
    font-weight: 700;
  }

  div {
    margin: 10px 0;
  }

  a {
    font-weight: 700;
    color: black;
  }

  .tip {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  Button {
    margin-top: 25px;
  }
}
