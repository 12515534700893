@import 'src/variables.scss';

.container__dashboard-page {
  display: grid;
  grid-template-columns: 280px 1fr;
  margin-left: 40px;
  column-gap: 35px;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 10px;
    margin-left: 0;
  }
}

.dashboard-tab-icon-label {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  column-gap: 2px;
  cursor: pointer;
}

.dashboard-container-info {
  @media screen and (max-width: 950px) {
    padding-left: 20px;
    width: 100vw;
  }
}
