@import 'src/variables.scss';

.container {
  height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: 201;
  text-align: center;
}

.titleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.title {
  margin: 15px 0 15px 15px;
}

.textContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.bodyText {
  padding-top: 20px;
  border-top: 1px solid $background-grey;
  color: $text-grey;
}

.resendText {
  padding-top: 20px;
  color: $text-grey;
}

.verificationResentVisible {
  visibility: visible !important;
}

.verificationResent {
  color: white;
  background-color: $success;
  padding: 5px 10px;
  visibility: hidden;
  align-self: center;
  border-radius: 3px;
  margin: 0 0 5px;
  font-weight: 600;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
