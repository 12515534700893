@import 'src/variables.scss';

.group-event-details__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  padding-top: 40px;
  background: $light-grey;

  position: relative;
  word-break: break-word;
}

.group-event-details__modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.group-event-details__card {
  max-width: 972px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  padding-top: 25px;
}

.group-event-details__header {
  border-bottom: 1px $tag-grey solid;
}

.group-event-details__header-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px $tag-grey solid;
  padding: 0 33px 16px 33px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
}

.group-event-details__header-date-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.group-event-details__header-date-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.group-event-details__title {
  font-size: 36px;
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 650px) {
    font-size: 20px;
  }
}

.group-event-details__subtitle {
  font-size: 28px;
  font-weight: 600;
  color: #7cc0f2;
  margin: 0;
  @media screen and (max-width: 650px) {
    font-size: 16px;
  }
}

.group-event-details__header-time-text {
  font-size: 36px;
  font-weight: 600;
  @media screen and (max-width: 650px) {
    font-size: 20px;
  }
}

.group-event-details__header-date-text {
  font-size: 36px;
  font-weight: 600;
  margin-left: 5px;
  @media screen and (max-width: 650px) {
    font-size: 20px;
  }
}

.group-event-details__header-timezone-text {
  font-size: 28px;
  font-weight: 600;
  @media screen and (max-width: 650px) {
    font-size: 16px;
  }
}

.group-event-details__header-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 33px 12px 33px;
  width: 100%;
  position: relative;
  @media (max-width: 800px) {
    padding: 0;
    align-items: flex-end;
  }
}

.group-event-details__header-buttons-subcontainer {
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex: 0.66 !important;
    align-self: stretch;
    justify-content: flex-end;
    max-width: none;
  }
}

.group-event-details__social-share-button {
  display: flex;
  align-items: center;

  width: 150px;
  border-radius: 2px;
  padding: 9px 20px;

  &:hover {
    background-color: white;
    color: $text-dark-blue !important;
    box-shadow: 0 0 0 1px $text-dark-blue;
  }

  @media (max-width: 800px) {
    flex: 1;
  }

  img {
    margin-right: 7px;
  }
}

.group-event-details__social-share-container {
  position: relative;
}

.group-event-details__social-share-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 14px;

  position: absolute;
  background: white;
  bottom: -400%;
  left: 0;
  z-index: 3;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
}

.group-event-details__social-share-modal-copy-text {
  margin-top: 10px;
}

.group-event-details__social-share-modal-copy-container {
  display: flex;
  width: 100%;
  pointer-events: initial;
  margin-top: 8px;
}

.group-event-details__social-share-modal-copy-input {
  flex: 2;
  background: $dark-grey;
  font-family: inherit;
}
.group-event-details__social-share-modal-copy-button {
  flex: 1;
}

.group-event-details__atc-button {
  margin-left: 10px;
}

.group-event-details__register-button {
  flex: 1;
  max-width: 375px;
}

.group-event-details__register-button--disabled {
  cursor: default;
}

.group-event-details__body {
  display: flex;
  width: 100%;
  padding: 0 33px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.group-event-details__description-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 43px;
  padding-bottom: 20px;
  white-space: pre-line;
}

.group-event-details__date-location-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  @media (max-width: 650px) {
    margin-top: 20px;
  }
}

.group-event-details__date-title {
  font-weight: 700;
  margin: 0 0 5px;
}

.group-event-details__location-title {
  margin: 10px 0 5px;
  font-weight: 700;
}

.group-event-details__volunteer-profile-container {
  flex: 1;
  padding-left: 43px;
  border-left: 1px $tag-grey solid;
}

.group-event-details__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.group-event-details__loading-spinner {
  height: 200px;
  width: 200px;
}

.group-event-details__error {
  color: $error;
}
