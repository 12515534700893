.empty-plan-container {
  width: 950px;
  margin-top: 20px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--shade-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

.edit-plan-container {
  width: 950px;
  margin-top: 20px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--shade-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

.divider {
  width: 860px;
  height: 1px;
  background: #ededed;
  @media screen and (max-width: 1000px) {
    width: 300px;
  }
}
.row-plan {
  display: grid;
  grid-template-columns: 641px 1px 309px;
}
.row-plan-details {
  display: grid;
  grid-template-columns: 541px 1px 154px 1px 154px;
}
.row-plan-details-mobile {
  display: inline-flex;
  flex-direction: column;
}

.plan-details-container {
  width: 860px;
  margin-top: 20px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--shade-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.plan-details-container-mobile {
  width: 300px;
  flex-shrink: 0;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  background: var(--shade-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
.vertical-divider {
  width: 1px;
  height: 100%;
  background: #ededed;
}
.vertical-divider-mobile {
  width: 1px;
  background: #ededed;
}

.table-text {
  height: 27px;
  padding-top: 8px;
  color: rgb(95, 94, 94);
  font-family: Gilroy;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}
.table-text-mobile {
  height: 27px;
  padding-top: 8px;
  color: rgb(95, 94, 94);
  font-family: Gilroy;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.table-text-options {
  height: 27px;
  padding-top: 8px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;
  align-items: center;
  color: #777;
  font-family: Gilroy;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.table-text-options-mobile {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;
  align-items: center;
  color: #777;
  font-family: Gilroy;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.visibility-text {
  color: #333;
  display: inline-flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  text-align: center;
  font-family: Gilroy;
  align-items: center;
  row-gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
}

.visibility-box {
  display: grid;
  grid-template-columns: 154px 1px 154px;
}

.actionplan-container {
  width: 850px;
  @media screen and (max-width: 900px) {
    width: 100%;
    min-width: unset;
  }
}
.actionplan-container-mobile {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.status-column-container {
  display: inline-flex;
  flex-direction: row;
  column-gap: 25px;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 25px;
  width: 100%;
  height: auto;
}
.status-column-container-mobile {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.status-column {
  color: #000;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  flex-direction: column;
  font-family: Gilroy;
  font-size: 17px;
  padding: 10px;
  padding-left: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 20.4px */
  letter-spacing: 0.17px;
  width: 260px;
  border-radius: 10px;
  min-height: 115px;
  flex-shrink: 0;
}
.to-do {
  background-color: #fcebeb;
}

.in-progress {
  background-color: #fff9e4;
}

.completed {
  background-color: #ecfbf0;
}
.class-header-mobile {
  align-self: flex-start;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--Shade-Grey-5, #333);
  text-align: center;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
}

.boldText {
  font-weight: bold;
}

.decline-action-plan {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 131%; /* 26.2px */
  letter-spacing: 0.4px;
}

.decline-action-plan-button-wrapper {
  display: inline-flex;
  column-gap: 20px;
  margin-top: 30px;
}

.go-back-text {
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: italic;
  font-weight: 800;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
}

.calendar-date-task {
  font-family: 'Gilroy';
  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
}
