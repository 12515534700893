@import 'src/variables.scss';

.profile-photo-card {
  width: 58px;
  height: 58px;
  flex-grow: 0;
  border: 1px solid rgb(114, 111, 111);
  border-radius: 50%;
}

.reschedule-paper-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: -15px;
  @media screen and (max-width: 950px) {
    margin-top: 10px;
  }
}

.reschedule-paper-header {
  display: grid;
  grid-template-columns: 80px auto;
}

.reschedule-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin-top: 5px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 14px;
    column-gap: 5px;
    font-weight: 500;
    margin-left: -35px;
  }
}

.reschedule-group-event-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin: 0px 0 0 80px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.reschedule-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #2f80ed;
  padding-left: 4px;
}

.reschedule-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  @media screen and (max-width: 950px) {
    width: 180px;
    overflow-wrap: break-word;
  }
}

.reschedule-paper-button-wrapper {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: min-content min-content min-content min-content;
  @media screen and (max-width: 950px) {
    grid-template-columns: auto;
    align-items: center;
    width: 100%;
  }
}

.reschedule-paper-slots {
  padding-top: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}

.reschedule-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.reschedule-rectangle-icon {
  display: flex;
  background-color: #8ac70a;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.reschedule-rectangle-icon:after {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}

.reschedule-dialog-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 14px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 34px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.confirm-dialog-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 14px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: center;
  color: #000;
  @media screen and (max-width: 950px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.reschedule-dialog-title {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 16px;
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 24px;
  }
}

.confirm-reschedule-dialog-title {
  text-align: center;
  margin: 40px;
  margin-bottom: 20px;
  align-items: center;
  font-family: Gilroy;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 17px;
  }
}
.confirm-reschedule-button {
  display: inline-flex;
  margin: 20px;
  margin-bottom: 40px;
  justify-content: center;
}
.reschedule-dialog-date-text {
  margin: 0 0 0 5px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #333;
}

.reschedule-confirm-date-text {
  margin: 0 0 0 5px;
  font-family: Gilroy;
  font-size: 17px;
  margin-top: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: center;
  color: #333;
}
.reschedule-dialog-date-text {
  display: inline-flex;
  flex-direction: row;
}

.close-dialog-icon {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.reschedule-paper-box {
  width: 603px;
  height: 214px;
  flex-grow: 0;
  padding-left: 20px;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;

  @media screen and (max-width: 950px) {
    width: 328px;
    height: 386px;
    max-width: 100%;
  }
}

.reschedule-helper-text {
  padding-top: 15px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  margin-top: -20px;
  text-align: left;
  width: 90%;
  color: #777;
}
