@import 'src/variables.scss';

.wrapper {
  max-width: 800px;
}

.container {
  display: flex;
  justify-content: center;
  a {
    font-weight: bold;
    text-decoration: underline;
    color: $dark-shade-grey;
    font-size: 18px;
    font-weight: 700 !important;
  }
  .options-buttons {
    display: flex;
    flex-direction: row;
    width: 600px;
    justify-content: space-between;
    .remove-button {
      margin-top: 0px;
      width: 250px;
    }
  }
}

.thankYou {
  p {
    font-size: 20px;
  }
}

.header {
  color: $shade-grey;
  margin-left: 15%;
}

.successHeader {
  font-size: 28px;
}

.outerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  max-width: 700px;
}

.successSeparator {
  border-bottom: 1px solid $dark-shade-grey;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 40px;
}

.optionsButtons {
  display: flex;
  flex-direction: column;
  input[type='radio'] {
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $shade-grey;
  }
  textarea {
    font-size: 18px;
    min-height: 120px;
    font-family: gilroy;
  }
}

.removeButton {
  align-self: flex-end;
  margin-top: 30px;
  margin-right: -30px !important;
  background-color: $highlight-green;
  color: $background-black;
  border-color: $background-black;
  width: 200px;
  font-weight: 600;
  &:hover {
    background-color: $tag-green;
  }
}

.separator {
  width: 200px;
  margin-top: 50px;
  margin-bottom: 20px;
  border-bottom: 2px solid $highlight-green;
  align-self: center;
}

.reasonText {
  font-weight: 200;
  color: $shade-grey;
}

.linkSuccess {
  font-weight: bold;
  text-decoration: underline;
  color: $dark-shade-grey;
  font-size: 18px;
  font-weight: 700 !important;
}

.textSuccess {
  color: $shade-grey;
  font-weight: 600;
  margin-bottom: 40px;
  margin-left: 40px;
}

.calendarTextContainer {
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.calendarText {
  margin: 0 0 0 15px !important;
  vertical-align: middle;
  color: $shade-grey;
  font-weight: 600;
}

.error {
  color: $error;
}

.optionsButtonsSuccess {
  display: flex;
  justify-content: space-between;
  input[type='radio'] {
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  textarea {
    font-size: 18px;
    min-height: 120px;
    font-family: gilroy;
  }
}

.removeButtonSuccess {
  width: 250px;
  margin-right: -18px !important;
  font-weight: 600;
}

.textError {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  color: $error;
  font-family: gilroy;
}

.text {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: gilroy;
}

.title {
  font-size: 30px;
}

.subtitle {
  font-size: 24px !important;
}
