@import '../../../../styling/palette.scss';
.dashboard-cards {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  @media screen and (max-width: 950px) {
    width: 95vw;
  }
}

.filter-menu-title {
  margin-top: 16px;
  margin-left: 15px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
}

.filters-dashboard {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  @media screen and (max-width: 950px) {
    margin-right: 20px;
    font-size: 16px;
  }
}

.filter-error-text {
  width: 500px;
  max-width: 80vw;
  margin-top: 50px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  @media screen and (max-width: 950px) {
    font-size: 14px;
    width: 300px;
  }
}

.new-dashboard-container {
  display: flex;
  column-gap: 20px;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
}

.dashboard-impact-section {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.dashboard-impact-section-title {
  font-family: Gilroy;
  margin-left: 20px;
  margin-top: 30px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: left;
  color: #000;
}

.dashboard-impact-title-container {
  display: inline-flex;
  justify-content: space-between;
  width: 350px;
  height: 50px;
  align-items: center;
  text-align: center;
}

.dashboard-news-section {
  max-width: 450px;
}

.news-carousel .slick-slide > div {
  margin-right: 0 !important;
}
