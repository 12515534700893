.message-center-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;

  @media screen and (max-width: 950px) {
    gap: 10px;
  }

  .message-center-header-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    @media screen and (max-width: 950px) {
      flex: 1;
    }

    .message-center-mobile-back {
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
    }

    .message-center-header-photo {
      height: 27px;
      width: 27px;
      border-radius: 27px;
      border: 1px solid #e8e8e8;
      object-fit: cover;
      cursor: pointer;
    }

    .message-center-header-name {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.51px;
      color: #000;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      @media screen and (max-width: 950px) {
        width: 100px;
      }
    }

    .message-center-header-request {
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.42px;
      color: #2f80ed;
      cursor: pointer;
    }
  }

  .message-center-header-align-left {
    justify-content: flex-end;
  }
}
