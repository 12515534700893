@use 'sass:color';
@import 'src/variables.scss';

.general__title {
  margin: 0;
  font-size: 18px;
}

.general__error {
  color: red;
  font-weight: 700;
  font-size: 25px;
  margin-top: 0;
}

.general__container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  margin: 0 20px 20px 20px;
}

.mandatory-field-asterisk-icon {
  color: red;
  font-size: 30px;
  margin-left: 7px;
  align-self: start;
}

.required-field-with-asterisk {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
