@import 'src/variables.scss';

.tooltip__wrapper {
  background: $background-grey;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  margin-left: 6px;
  margin-bottom: -2px;
  z-index: 5;
  &:hover {
    background: $light-green;
    img {
      filter: brightness(0%);
    }
  }
  img {
    position: absolute;
    top: 3px;
    left: 5px;
  }
}
