@import '~include-media/dist/_include-media.scss';

:root {
  --mslb-bg-color: #fff;
  --mslb-font-color: #000;
  --mslb-border-color: #d3c9c9;
  --mslb-btn-font-color: #000;
  --mslb-btn-bg-add-color: c2c2c2;
  --mslb-btn-bg-remove-color: #ff5252;
  --mslb-item-hover-bg-color: #f5f5f5;
}

$primary-blue: #0f0f22;
$secondary-blue: #f7f9fb;
$matte-blue: #d7e3ec;
$highlight-blue: #1589ee;
$background-black: #0f0f22;
$highlight-green: #53ffc8;
$shade-grey: #777777;
$dark-shade-grey: #414141;
$linked-in-blue: #0072b1;
$dark-grey: #f2f2f2;
$dark-blue: #191e39;
$text-dark-blue: #181f39;
$light-grey: #f3f7fa;
$light-green: c2c2c2;
$text-grey: #333333;
$background-grey: #c4c4c4;
$background-blue: #181f39;
$tag-grey: #e8e8e8;
$tag-blue: #b2d5ef;
$tag-green: #d7ffee;
$success: #00ab6b;
$error: #f44336;
$notification: #f0382e;

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

$breakpoints: (
  phone: 576px,
  tablet: 768px,
  desktop: 992px,
  desktop-large: 1200px,
);
