.signup-complete__container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.signup-complete__inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signup-complete__buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
