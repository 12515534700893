@import 'src/variables.scss';

.group-event-explainer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
  padding: 0 50px;
  text-align: left;
  white-space: pre-line;
}

.group-event-explainer__title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.group-event-explainer__title-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid $shade-grey;
  padding: 0 0 24px 0;
  max-width: 1200px;
  flex: 1;
}

.group-event-explainer__title {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.group-event-explainer__subtitle {
  color: $light-green;
  margin: 8px 0 0 0;
  font-size: 28px;
  font-weight: 700;
}

.group-event-explainer__body-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  flex: 1;
  padding: 0 0 33px 0;
  border-bottom: 1px solid $tag-grey;
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
}

.group-event-explainer__thank-you-text {
  margin-top: 24px;
}

.group-event-explainer__availability-title {
  font-size: 24px;
}

.group-event-explainer__error-text {
  color: $error;
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
}

.group-event-explainer__recording-checkbox-label {
  margin-top: 39px;
  max-width: 675px;
  display: flex;
}

.group-event-explainer__button-container {
  width: 100%;
  text-align: right;
  margin-top: 72px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  -webkit-position: sticky;
  bottom: 0px;
  padding: 20px 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.7);
  button {
    max-width: 500px;
    display: flex;
    justify-content: center;
    padding: 12px 20px;
    pointer-events: auto;
  }
}

.group-event-explainer__submit-button {
  min-width: 300px;
}

.group-event-explainer__agenda-card {
  align-self: center;
}

.group-event-explainer__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.group-event-explainer__loading-spinner {
  height: 200px;
  width: 200px;
}
