@import 'src/variables.scss';

.profile-photo-card {
  width: 58px;
  height: 58px;
  border-radius: 50%;
}
.completed-paper-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
}
.completed-paper-header {
  display: grid;
  grid-template-columns: 80px auto;
}
.completed-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 14px;
    width: 200px;
  }
}

.completed-group-event-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin: 0px 0 0 80px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.completed-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  padding-left: 4px;
}
.completed-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  @media screen and (max-width: 950px) {
    inline-size: 50vw;
    overflow-wrap: break-word;
  }
}

.completed-paper-slots {
  padding-top: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.completed-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}
.completed-card__container {
  display: grid;
  grid-template-columns: 80px auto;
  padding-top: 40px;
  padding-left: 20px;
  @media screen and (max-width: 950px) {
    grid-template-columns: 50px 265px;
  }
}

.completed-paper-box {
  width: 683px;
  height: 230px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;

  @media screen and (max-width: 950px) {
    width: 328px;
    height: 350px;
  }
}

.completed-masterclass-paper-box {
  width: 603px;
  height: 199px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
}

.completed-paper-button-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
}

.completed-button-container {
  width: auto;
  min-width: 120px;
  text-align: left;
  @media screen and (max-width: 950px) {
    text-align: center;
    margin-left: -30px;
    width: 100%;
  }
}
