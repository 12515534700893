.row-card {
  display: grid;
  grid-template-columns: 240px 190px 240px;
  column-gap: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.empty-card-container {
  margin-top: 20px;
  flex-shrink: 0;
  width: 100%;
  border-radius: 10px;
}

.card-title {
  color: #000;
  font-family: Gilroy;
  font-size: 20px;
  width: 640px;
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}
.card-name-displayed {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
}
.card-deadline-displayed {
  color: #777;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.12px;
}
.card-description-displayed {
  color: var(--shade-grey-5, #333);
  font-family: Gilroy;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.task-card-container {
  justify-content: space-between;
  padding-bottom: 10px;
  width: 225px;
  padding-right: 10px;
  height: 104px;
  margin-bottom: 10px;
  margin-top: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  row-gap: 3px;
  padding-left: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding-top: 15px;
}
.task-card-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  cursor: pointer;
  height: 100%;
}

.card-management-details {
  width: 800px;
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px;

  flex-shrink: 0;
  margin-top: 10px;
  border-radius: 15px;
  background: #fff;
}

.new-card-management-details {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  min-height: 280px;

  height: auto;
  border-radius: 15px;
  padding-top: 20px;
  background: #fff;
}
.card-management-title {
  color: #333;
  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 30.8px */
  letter-spacing: 0.28px;
}
.row-card-management {
  display: grid;
  grid-template-columns: 500px 200px;
}
.row-card-management-2 {
  display: grid;
  grid-template-columns: 150px 350px 200px;
}
.row-card-input {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.card-management-text {
  color: #333;
  width: 100%;
  margin-left: 20px;
  height: 27px;
  padding-top: 8px;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
}

.new-card-management-text {
  color: #333;
  margin-left: 20px;
  min-height: 27px;
  height: auto;
  padding-top: 8px;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;
  white-space: pre-wrap;
  max-height: 200px;
  overflow: auto;
}
.card-buttons {
  column-gap: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.card-button {
  width: 10px;
  cursor: pointer;
}

.card-date {
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: #777;
  font-family: Gilroy;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.12px;
}

.card-description {
  color: #333;
  height: 100%;
  font-family: Gilroy;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.new-card-management-buttons {
  align-self: flex-end;
  column-gap: 20px;
  display: flex;
  padding-right: 30px;
  @media screen and (max-width: 700px) {
    align-self: flex-start;
    padding-left: 20px;
  }
}

.select-availability-action-plan {
  width: 190px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
}

.card-management-container {
  width: 900px;
  height: 470px;
  flex-shrink: 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcebeb;
  margin-top: 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

.ticket-status-buttons {
  display: inline-flex;
  column-gap: 10px;
  width: 100%;
  padding-bottom: 20px;
  padding-right: 42px;
  justify-content: flex-end;
}

.permission-popup-text {
  color: #333;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-left: 20px;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.action-plan-popup__body {
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  width: 600px;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
  .new-message__header {
    width: 100%;
    padding: 32px 24px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 950px) {
      padding: 16px 16px 8px;
    }

    p {
      font-family: 'Gilroy';
      font-size: 24px;
      font-weight: bold;
      line-height: 1.1;
      letter-spacing: 0.28px;
      color: #333333;
      margin: 0;
      padding: 0;
    }

    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .new-message__bottom {
    width: 100%;
    padding: 25px 24px 23px;
    box-sizing: border-box;

    @media screen and (max-width: 950px) {
      padding: 25px 16px 16px;
    }
  }

  .new-message__no-users {
    width: 100%;
    padding: 12px 24px 0;
  }
}

.user-given-permission-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  margin-left: 20px;
  width: 500px;
  height: 220px;
  overflow: auto;
  @media screen and (max-width: 950px) {
    margin: 0px;
    height: 180px;
    overflow: auto;
  }
}
.user-given-permission {
  display: inline-flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 950px) {
    flex-wrap: wrap;
  }
}
.new-mentor-select-card__photo {
  height: 58px;
  width: 58px;
  border-radius: 58px;
  border: 1px solid #e8e8e8;
  background: white;
  object-fit: cover;
  margin: 0 8px 0 16px;
}
.new-mentor-select-card__name {
  font-family: 'Gilroy';
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.51px;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.permission-button {
  color: #000;
  display: inline-flex;
  align-items: center;
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.51px;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.permission-buttons-wrapper {
  display: inline-flex;
  column-gap: 10px;
  height: 50px;
}

.user-information {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
  @media screen and (max-width: 950px) {
    width: 250px;
  }
}

.quick-permission-button-wrapper {
  display: inline-flex;
  column-gap: 10px;
  @media screen and (max-width: 950px) {
    width: 250px;
  }
}
