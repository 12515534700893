@import 'src/variables.scss';

.radio-btn__unseleted {
  display: block;
  min-height: 22.5px;
  min-width: 22.5px;
  max-height: 22.5px;
  max-width: 22.5px;
  border-radius: 22.5px;
  border: 2px solid #b2d5ef;
  background-color: white;
}

.radio-btn__selected {
  display: block;
  min-height: 22.5px;
  min-width: 22.5px;
  max-height: 22.5px;
  max-width: 22.5px;
  border-radius: 22.5px;
  background-color: white;
}
