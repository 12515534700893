.resource-card-container {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);

  .resource-card-image-container {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .resource-card-title {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 16px 0;
  }

  .resource-card-row-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .resource-card-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      .resource-card-text {
        font-family: Gilroy;
        font-size: 12px;
        font-weight: 500;
        color: #333;
        text-transform: capitalize;
      }
    }
  }
}
